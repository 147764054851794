import { useState } from "react";
import styled from "styled-components";
import { LinkFormContents } from "components";
import useApi from "../../hooks/useApi";
import { useMe, MeGate } from "../../store";
import { cleanLicensePlate } from "utils";

const LinkContainer = styled.section`
  display: flex;
  flex: 1;
  align-self: center;
  flex-direction: column;
`;

const NoCustomer = () => {
  const me = useMe();
  const [error, setError] = useState(undefined);

  const { fetchPOST } = useApi();

  const handleSubmit = async (values) => {
    // strip out spaces and capitalize
    const cleanLP = cleanLicensePlate(values.licensePlate);
    const siteWatchCode = `${cleanLP}-${values.state}`;

    setError(undefined);
    try {
      await fetchPOST(`linkcustomer`, {
        customer_code: siteWatchCode,
      });

      await me.refetch();
    } catch (e) {
      setError(e);
    }
  };

  return (
    <LinkContainer>
      <MeGate>
        <LinkFormContents
          handleSubmit={handleSubmit}
          error={error}
          me={me.data}
        />
      </MeGate>
    </LinkContainer>
  );
};

export default NoCustomer;
